import * as React from "react"
import {Link} from "gatsby"
import lottie from "lottie-web"
import Loadable from "@loadable/component"

// images import
import IMG_A from "../images/landing-img-a.svg"
import IMG_B from "../images/landing-img-b.svg"
import IMG_C from "../images/landing-img-c.svg"
import IMG_D from "../images/landing-img-d.svg"
import IMG_GOOGLE_PLAY from "../images/btn-google-play.svg"
import IMG_APP_STORE from "../images/btn-app-store.svg"

// animations import
import qrcode from "../animations/qrcode.json"
import monitoring from "../animations/monitoring.json"
import work_safely from "../animations/work-safely.json"
import hearing_voice from "../animations/hearing-voice.json"
import health_status from "../animations/health-status.json"

// components import
import CardList from "../components/card-list"
import {LogoWithText} from "../components/base/logo-with-text"
import SEO from "../layouts/seo"

import {noop} from "../utils/noop"

import "./landing-page.scss"

const LoadableSignUpForm = Loadable(() =>
  import("../components/sign-up-hsforms-modal"),
)

const MARGIN_TOP = 250
export default class landingPage extends React.Component {
  state = {
    animationContainers: [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ],
    currentPlay: 0,
    hover: false,

    signUpModalShow: false,
  }

  constructor(props) {
    super(props)
    this.handleAnimPlay = this.handleAnimPlay.bind(this)
    this.handleAnimStop = this.handleAnimStop.bind(this)
    this.anims = []
    this.pauseAll = this.pauseAll.bind(this)
    this.handleComplete = this.handleComplete.bind(this)
    this.changePlayTitle = this.addPlayEffect.bind(this)
    this.removePlayTitle = this.removePlayTitle.bind(this)
    this.handleTryIt = this.handleTryIt.bind(this)
    this.closeSignUpModal = this.closeSignUpModal.bind(this)
    this.openSignUpModal = this.openSignUpModal.bind(this)
  }

  handleTryIt() {
    this.openSignUpModal()
  }

  componentWillUnmount() {
    for (let i = 0; i < this.anims.length; i++) this.removePlayTitle(i)
    this.anims.forEach(anim => {
      anim.stop()
      anim.destroy()
    })
  }

  componentDidMount() {
    ;[qrcode, health_status, work_safely, hearing_voice, monitoring].forEach(
      (json, index) => {
        const anim = lottie.loadAnimation({
          container: this.state.animationContainers[index].current,
          renderer: "svg",
          loop: false,
          autoplay: false,
          animationData: json,
        })
        anim.onComplete = this.handleComplete(index)
        this.anims.push(anim)
      },
    )

    document
      .querySelector(".delay-600ms")
      .addEventListener("animationend", () => {
        this.anims[this.state.currentPlay].play()
        this.addPlayEffect(this.state.currentPlay)
      })
  }

  addPlayEffect(index) {
    if (this.state.animationContainers[index].current === null) return
    this.state.animationContainers[index].current.parentNode
      .querySelector(".landing-card-label")
      .classList.add("landing-card-label-play")
    this.state.animationContainers[
      index
    ].current.parentNode.parentNode.classList.add("play-effect")
  }

  removePlayTitle(index) {
    if (this.state.animationContainers[index].current === null) return
    this.state.animationContainers[index].current.parentNode
      .querySelector(".landing-card-label")
      .classList.remove("landing-card-label-play")
    this.state.animationContainers[
      index
    ].current.parentNode.parentNode.classList.remove("play-effect")
  }

  handleAnimPlay(index) {
    return () => {
      let timer = this.state.timer
      this.pauseAll()
      this.anims[index].stop()
      this.anims[index].play()
      this.addPlayEffect(index)
      this.setState({hover: true, timer})
    }
  }

  handleAnimStop(index) {
    return () => {
      this.setState({hover: false})
      this.anims[index].pause()
      this.anims[this.state.currentPlay].play()
      this.addPlayEffect(this.state.currentPlay)
      this.removePlayTitle(index)
    }
  }

  handleComplete(index) {
    return () => {
      if (this.state.hover) return
      let timer = this.state.timer
      this.removePlayTitle(index)
      if (index > 1) {
        this.anims[index].stop()
      }
      const currentPlay = index < this.anims.length - 1 ? index + 1 : 0
      // console.log(`play ${currentPlay}`)
      this.anims[currentPlay].stop()
      this.anims[currentPlay].play()
      this.addPlayEffect(currentPlay)
      this.setState({currentPlay, timer})
    }
  }

  pauseAll() {
    for (let i = 0; i < this.anims.length; i++) this.removePlayTitle(i)
    this.anims.map(anim => anim.pause())
  }

  openSignUpModal() {
    this.setState({
      signUpModalShow: true,
    })
  }

  closeSignUpModal() {
    this.setState({
      signUpModalShow: false,
    })
  }

  render() {
    return (
      <div className="landing-page-container">
        <SEO title="LandingPage" />
        <div className="row landing-page-header">
          <LogoWithText />
          <div>
            <Link to="signin">
              <div className="sign-in-button" role="button">
                <span className="button-label">Sign In</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="landing-page-content">
          <div className="landing-page-label animated bounceIn">
            Back to life, back <br />
            to normality
          </div>
          <div className="row apps-button-row">
            <a
              style={{marginRight: 10}}
              href="https://apps.apple.com/tw/app/dt-smartworks-app/id1532171290"
              rel="noreferrer"
              target="_blank">
              <img
                src={IMG_APP_STORE}
                alt={IMG_APP_STORE}
                width={160}
                height={48}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.dropthought.app.smartworks"
              rel="noreferrer"
              target="_blank">
              <img
                src={IMG_GOOGLE_PLAY}
                alt={IMG_GOOGLE_PLAY}
                width={160}
                height={48}
              />
            </a>
          </div>
          <div
            className="try-it"
            onClick={this.handleTryIt}
            role="button"
            onKeyDown={noop}
            tabIndex="0">
            <div className="button-label">Sign Up</div>
          </div>
          <div className="row">
            <CardList
              className="animated fadeInUp delay-50ms"
              style={{marginTop: `${MARGIN_TOP * -3}px`}}
              items={[
                <div>
                  <img
                    className="img-illustration"
                    src={IMG_C}
                    alt={IMG_C}
                    width={86}
                    height={140}
                  />
                </div>,
                <div>
                  <img
                    className="img-illustration"
                    src={IMG_D}
                    alt={IMG_D}
                    width={86}
                    height={140}
                  />
                </div>,
                <div />,
              ]}
            />
            <CardList
              className="animated fadeInUp delay-100ms"
              style={{marginTop: `${MARGIN_TOP * -2}px`}}
              items={[
                <div
                  className="card-content"
                  onMouseEnter={this.handleAnimPlay(0)}
                  onMouseLeave={this.handleAnimStop(0)}>
                  <div className="card-content-container">
                    <div
                      className="qrcode"
                      ref={this.state.animationContainers[0]}
                    />
                    <div className="landing-card-label">
                      Simply One-time <br />
                      QR code
                    </div>
                  </div>
                </div>,
                <div>
                  <img
                    className="img-illustration"
                    src={IMG_A}
                    alt={IMG_A}
                    width={140}
                    height={140}
                  />
                </div>,
                <div />,
              ]}
            />
            <CardList
              className="animated fadeInUp delay-200ms"
              style={{marginTop: `${MARGIN_TOP * -1}px`}}
              items={[
                <div
                  className="card-content"
                  onMouseEnter={this.handleAnimPlay(1)}
                  onMouseLeave={this.handleAnimStop(1)}>
                  <div className="card-content-container">
                    <div
                      className="anim-health"
                      ref={this.state.animationContainers[1]}></div>
                    <div className="landing-card-label">
                      Health Status at Glance
                    </div>
                  </div>
                </div>,
                <div>
                  <img
                    className="img-illustration"
                    src={IMG_B}
                    alt={IMG_B}
                    width={140}
                    height={95}
                  />
                </div>,
                <div />,
              ]}
            />
            <CardList
              className="animated fadeInUp delay-300ms"
              items={[
                <div
                  className="card-content"
                  onMouseEnter={this.handleAnimPlay(2)}
                  onMouseLeave={this.handleAnimStop(2)}>
                  <div className="card-content-container">
                    <div
                      className="anim_work"
                      ref={this.state.animationContainers[2]}></div>
                    <div className="landing-card-label">
                      Back to Work Safely
                    </div>
                  </div>
                </div>,
                <div />,
                <div />,
              ]}
            />
            <CardList
              className="animated fadeInUp delay-400ms"
              style={{marginTop: `${MARGIN_TOP * -1}px`}}
              items={[
                <div
                  className="card-content"
                  onMouseEnter={this.handleAnimPlay(3)}
                  onMouseLeave={this.handleAnimStop(3)}>
                  <div className="card-content-container">
                    <div
                      className="hearing-voice"
                      ref={this.state.animationContainers[3]}
                    />
                    <div className="landing-card-label">
                      Hearing Voice of Customers
                    </div>
                  </div>
                </div>,
                <div>
                  <img
                    className="img-illustration"
                    src={IMG_D}
                    alt={IMG_D}
                    width={86}
                    height={140}
                  />
                </div>,
                <div />,
              ]}
            />
            <CardList
              className="animated fadeInUp delay-500ms"
              style={{marginTop: `${MARGIN_TOP * -2}px`}}
              items={[
                <div
                  className="card-content"
                  onMouseEnter={this.handleAnimPlay(4)}
                  onMouseLeave={this.handleAnimStop(4)}>
                  <div className="card-content-container">
                    <div
                      className="monitor"
                      ref={this.state.animationContainers[4]}
                    />
                    <div className="landing-card-label">
                      Easy Monitoring and Management
                    </div>
                  </div>
                </div>,
                <div>
                  <img
                    className="img-illustration"
                    src={IMG_C}
                    alt={IMG_C}
                    width={86}
                    height={140}
                  />
                </div>,
                <div />,
              ]}
            />
            <CardList
              className="animated fadeInUp delay-600ms"
              style={{marginTop: `${MARGIN_TOP * -3}px`}}
              items={[
                <div>
                  <img
                    className="img-illustration"
                    src={IMG_A}
                    alt={IMG_A}
                    width={140}
                    height={140}
                  />
                </div>,
                <div>
                  <img
                    className="img-illustration"
                    src={IMG_B}
                    alt={IMG_B}
                    width={140}
                    height={95}
                  />
                </div>,
                <div />,
              ]}
            />
          </div>
        </div>
        <LoadableSignUpForm
          show={this.state.signUpModalShow}
          onCloseClick={this.closeSignUpModal}
        />
      </div>
    )
  }
}
