import React from "react"
import moduleStyles from "./card-list.module.scss"

export default function CardList({items, className = "", style = {}}) {
  return (
    <div className={`column`} style={style}>
      {items.map((item, index) => (
        <div
          key={index}
          className={`${moduleStyles.landingPageCard} ${className}`}>
          {item}
        </div>
      ))}
    </div>
  )
}
